import service from '@/api/service/service'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useServiceCheck() {
  const searchtext = ref('')

  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const options = ref({})
  const loading = ref(false)
  const footer = ref(i18n.locale === 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'id',
      width: 50,
    },
    {
      text: i18n.t('customer_code'),
      value: 'customer_id',
      width: 100,
    },
    {
      text: i18n.t('customer_patient'),
      value: 'customer_fname',
      width: 200,
    },
    {
      text: i18n.t('gender'),
      value: 'customer_gender',
      align: 'center',
      width: 80,
    },
    {
      text: i18n.t('tel'),
      value: 'customer_tel',
      width: 100,
    },

    {
      text: i18n.t('all_courses'),
      value: 'course_all',
      align: 'center',
      width: 120,
    },
    {
      text: i18n.t('waiting_service'),
      value: 'course_waiting_service',
      align: 'center',
      width: 120,
    },
    {
      text: i18n.t('use_service'),
      value: 'course_service',
      align: 'center',
      width: 120,
    },
    {
      text: i18n.t('last_used'),
      value: 'use_date',
      width: 150,
    },
  ])

  const statusGetCourse = ref('')
  const dialog = ref(false)
  const totalCount = ref(0)
  const dataCourseList = ref([])
  const detailLoading = ref(false)

  const { serviceList, serviceDetailList } = service

  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = () => {
    loading.value = true
    serviceList({
      searchtext: searchtext.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      dataTableList.value = data
      totalPage.value = count_of_page
      totalDataTableList.value = count
      segmentId.value = segment
      loading.value = false
    })
  }

  const getCourseByStatus = (id, status) => {
    dataCourseList.value = []
    dialog.value = true
    detailLoading.value = true
    statusGetCourse.value = status
    totalCount.value = 0
    serviceDetailList({
      customer_id_pri: id,
      serving_status_id: status,
      lang: i18n.locale,
    }).then(res => {
      for (const item of res) {
        const checkCourse = dataCourseList.value.findIndex(
          subItem =>
            subItem.serve_id == item.serve_id &&
            subItem.course_id_pri == item.course_id_pri &&
            subItem.order_id == item.order_id,
        )
        if (checkCourse == -1) {
          item.count = 1
          dataCourseList.value.push(item)
        } else {
          dataCourseList.value[checkCourse].count += 1
        }
        totalCount.value++
      }
      detailLoading.value = false
    })
  }

  watch([searchtext, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    searchtext,
    dataTableList,
    totalDataTableList,
    segmentId,
    options,
    loading,
    footer,
    columns,
    getCourseByStatus,
    totalPage,
    statusGetCourse,
    dialog,
    totalCount,
    dataCourseList,
    detailLoading,

    fetchDataTable,
  }
}
