<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('Check service') }}
      </v-card-title>

      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            :placeholder="$t('wordingSearch')"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        hide-default-footer
        :footer-props="footer"
        :loading="loading"
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template #[`item.customer_gender`]="{ item }">
          <v-chip
            v-if="item.customer_gender == 'ชาย' || item.customer_gender == 'Male'"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`"
            color="primary"
            small
            label
          >
            <v-icon small>
              {{ icons.mdiHumanMale }}
            </v-icon>
            {{ item.customer_gender }}
          </v-chip>
          <v-chip
            v-else-if="
              item.customer_gender == 'หญิง' || item.customer_gender == 'Female.' || item.customer_gender == 'Female'
            "
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
            color="info"
            small
            label
          >
            <v-icon small>
              {{ icons.mdiHumanFemale }}
            </v-icon>
            {{ item.customer_gender }}
          </v-chip>
          <span v-else> - </span>
        </template>
        <template v-slot:[`item.course_all`]="{ item }">
          <span
            style="cursor: pointer"
            class="primary--text"
            @click="getCourseByStatus(item.customer_id_pri, 2)"
          >{{ item.course_all }}</span>
        </template>
        <template v-slot:[`item.course_waiting_service`]="{ item }">
          <span
            style="cursor: pointer"
            class="primary--text"
            @click="getCourseByStatus(item.customer_id_pri, 1)"
          >{{ item.course_waiting_service }}</span>
        </template>
        <template v-slot:[`item.course_service`]="{ item }">
          <span
            style="cursor: pointer"
            class="primary--text"
            @click="getCourseByStatus(item.customer_id_pri, 3)"
          >{{ item.course_service }}</span>
        </template>
        <template v-slot:[`item.use_date`]="{ item }">
          <span v-if="item.use_date">
            <ConverseDate :date="item.use_date" />
          </span>
          <span v-else>
            {{ $t("no_items_to_use") }}
          </span>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <v-dialog
      v-model="dialog"
      max-width="1000"
    >
      <v-card :loading="detailLoading">
        <v-card-title>
          {{ $t("check_course_services") }} <v-spacer></v-spacer>
          <v-btn
            icon
            color="error"
            @click="dialog = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table
            v-if="statusGetCourse == '2'"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    {{ $t("course_name") }}
                  </th>
                  <th class="text-left">
                    {{ $t("receipt_code") }}
                  </th>
                  <th class="text-left">
                    {{ $t("customer_patient") }}
                  </th>
                  <th class="text-left">
                    {{ $t("doctor") }}
                  </th>
                  <th class="text-left">
                    {{ $t("service_number") }}
                  </th>
                  <th class="text-left">
                    {{ $t("times") }}
                  </th>
                  <th class="text-left">
                    {{ $t("service_date") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in dataCourseList"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.course_name }}</td>
                  <td>{{ item.order_id }}</td>
                  <td>
                    {{ item.customer_fullname ? item.customer_fullname : "-" }}
                  </td>
                  <td>{{ item.user_fullname ? item.user_fullname : "-" }}</td>
                  <td>{{ item.serve_code ? item.serve_code : "-" }}</td>
                  <td>{{ item.count }}</td>
                  <td>
                    <ConverseDate :date="item.serving_date" />
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    {{ $t("total") }}
                  </td>
                  <td>{{ totalCount }}</td>
                  <td>{{ $t("times") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table
            v-if="statusGetCourse == '1'"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    {{ $t("course_name") }}
                  </th>
                  <th class="text-center">
                    {{ $t("amount") }}/{{ $t("times") }}
                  </th>
                  <th class="text-left">
                    {{ $t("receipt_code") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in dataCourseList"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ item.course_name }}

                    <ol class="mb-1">
                      <li
                        v-for="i, index in item.drug_list"
                        :key="index"
                      >
                        <DrugStatus :type="i.drug_category_id" />
                        {{ i.drug_name }} ({{ i.drug_unit }}) {{ $t('balance') }} : {{ i.servingdetail_book_amount }}
                      </li>
                    </ol>
                  </td>
                  <td class="text-center">
                    {{ item.count }}
                  </td>
                  <td>{{ item.order_id }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    {{ $t("total") }}
                  </td>
                  <td class="text-center">
                    {{ totalCount }}
                  </td>
                  <td>{{ $t("times") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table
            v-if="statusGetCourse == '3'"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    {{ $t("course_name") }}
                  </th>
                  <th class="text-left">
                    {{ $t("receipt_code") }}
                  </th>
                  <th class="text-left">
                    {{ $t("customer_patient") }}
                  </th>
                  <th class="text-left">
                    {{ $t("doctor") }}
                  </th>
                  <th class="text-left">
                    {{ $t("service_number") }}
                  </th>
                  <th class="text-left">
                    {{ $t("times") }}
                  </th>
                  <th class="text-left">
                    {{ $t("service_date") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in dataCourseList"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.course_name }}</td>
                  <td>{{ item.order_id }}</td>
                  <td>
                    {{ item.customer_fullname ? item.customer_fullname : "-" }}
                  </td>
                  <td>{{ item.user_fullname ? item.user_fullname : "-" }}</td>
                  <td>{{ item.serve_code ? item.serve_code : "-" }}</td>
                  <td>{{ item.count }}</td>
                  <td>
                    <ConverseDate :date="item.serving_date" />
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    {{ $t("total") }}
                  </td>
                  <td>{{ totalCount }}</td>
                  <td>{{ $t("times") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiHumanMale, mdiHumanFemale, mdiClose } from '@mdi/js'
import useServiceCheck from './useServiceCheck'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import DrugStatus from '@/components/basicComponents/DrugStatus.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    ConverseDate,
    Pagination,
    DrugStatus,
  },
  setup() {
    return {
      ...useServiceCheck(),
      icons: {
        mdiHumanMale, mdiHumanFemale, mdiClose,
      },
    }
  },
}
</script>
